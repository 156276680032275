import React from 'react'
import styled from 'styled-components'
import axios from "axios";
import { useState } from 'react';


export default function Wrap() {
    var address;
    const tokenAddress = '0x752DC265EAf6Da2Db0F8e4a32D5596D3f18e8701';
    const tokenSymbol = 'WAVN';
    const tokenDecimals = 18;
    const tokenImage = 'https://github.com/JunaidPeer/wrapped-avian/blob/dev/src/wavn.png?raw=true';
    const addtoken = () => {
        try {
            window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress,
                        symbol: tokenSymbol,
                        decimals: tokenDecimals,
                        image: tokenImage,
                    },
                },
            });
        } catch (error) {
            console.log(error);
        }
    };
    const [newaddress, setNewAddress] = useState({});

    const [balance, setBalance] = useState({});

    const [message, setMessage] = useState('');
    const handleChange = event => {
        setMessage(event.target.value);
        // console.log('value is:', event.target.value);
    };

    const [evmaddress, setEvmAddress] = useState('');
    const handleChange2 = event => {
        setEvmAddress(event.target.value);

        // console.log('value is:', event.target.value);
    };
    const getNewAddress = async () => {
        try {
            const res = await axios.get(`/api/getnewaddress`);
            setNewAddress(res.data);

            address = res.data.result;

            getBalance();
        }
        catch (err) {
            console.log(err);
        }
    };

    const getBalance = async () => {
        try {
            // console.log(address)
            const res = await axios.get(`https://api.avn.network/balance/${address}`);
            setBalance(res.data);

            const balance = res.data.result.balance;
            // console.log(balance);

            const amount = message * 100000000;
            // console.log(amount);
            if (balance != amount) {
                getBalance();

            } else if (balance == amount) {
               
                try {
                    await axios.post(`/api/mint`, {
                        to: evmaddress,
                        amount: message,
                    }).then(res => {
                  
                        alert("response: " + res.data.message);
                        
                    })
                } catch (err) {
                    console.log(err);
                }

            };
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <h1>Wrap Your Avian into WAVN</h1>
            <h2>Instructions</h2>
            <p>1. Enter the amount of Avian you want to wrap. Make sure it is in whole numbers | No decimal point. EG: (1, 99, 230000)</p>
            <p>2. Provide a valid Polygon address, which can be from Trust wallet, Metamask, or any other wallet.</p>
            <p>3. Click Wrap only once and wait for Avian address to show up.</p>
            <p>4. Send the exact amount of Avian you entered to the address shown up below. Make sure you do not "Subtract fee from the amount while sending"</p>
            <Caution> 5. At any point do not refresh this page, all your progress will be lost if you do so.</Caution>
            <p>6. Minting WAVN to your wallet can take time, please wait patiently and check your wallet. Additionally, you will be alerted with the Transaction id upon minting.</p>

        <Forum>
                <form>
                 
                    <input type="number" placeholder="Enter Amount" onChange={handleChange}
                        value={message} required />         
                    <input type="text" placeholder="Enter Address (0x....)" onChange={handleChange2} value={evmaddress} required />
                    <input value="Wrap" type="button" onClick={getNewAddress} />
                </form>
            </Forum>

            <div id='pop' class='form-popup'>
                <h1>Please send {message} Avian to this address: {newaddress.result} </h1>
                <Caution>NOTE: PLEASE DONT NOT REFRESH THIS PAGE AFTER SENDING  TO THE ABOVE ADDRESS</Caution>
                <h1> </h1>
            </div>
            <button onClick={addtoken}>Add WAVN to Wallet</button>
        </div>
    )

}
const Forum = styled.form`
    display: flex;
   width: 100%;
    height: 100%;
 margin: 10px;
        
    font-size: 20px;
    `;
const Caution = styled.p`
color: red;
font-weight: bold;
`;